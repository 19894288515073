<template>
  <el-card>
    <common-user-center-title :title="'申请推广员进度'"></common-user-center-title>
    <el-alert
      title="提示"
      type="warning"
      :closable="false"
      description="后台正在审核中，请耐心等待"
      show-icon>
    </el-alert>
    <el-form class="mt-2" :model="applyDistributionProgressInfo" label-width="100px">
      <el-row>
        <el-col :span="12" >
          <el-form-item label="申请状态" prop="apply">
            <el-input disabled v-if="applyDistributionProgressInfo.apply" value="已申请"></el-input>
            <el-input disabled v-else value="未申请"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="real_name">
            <el-input disabled  v-model="applyDistributionProgressInfo.result.real_name"></el-input>
          </el-form-item>
          <el-form-item label="门店名称" prop="store_name">
            <el-input disabled v-model="applyDistributionProgressInfo.result.store_name"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="type">
            <el-radio-group disabled v-model="applyDistributionProgressInfo.result.status">
              <el-radio :label="0">审核未通过</el-radio>
              <el-radio :label="1">审核中</el-radio>
              <el-radio :label="2">审核通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="拒绝原因" prop="reason">
            <el-input disabled v-model="applyDistributionProgressInfo.result.reason"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="toBeforePage">确定</el-button>
            <el-button @click="toBeforePage">返回上一页</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import {applyDistributionProgressUrl} from '@/api/index'
export default {
  name: 'DistributionStatus',
  components: {
    commonUserCenterTitle
  },
  data(){
    return{
      applyDistributionProgressInfo:{
        apply:false,
        result:{
          real_name:'',
          id_card:'',
          store_name:'',
          status:'',
          reason:''
        }
      },
    }
  },
  created () {
    this.initData()
  },
  methods:{
    initData(){
      this.getApplyDistributionProgressInfo()
    },
    // 查询推广员申请进度
    async getApplyDistributionProgressInfo(){
      const {data:res} = await this.$http.get(applyDistributionProgressUrl)
      console.log(res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.applyDistributionProgressInfo = res.data
    },
    // 返回上一页
    toBeforePage(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
